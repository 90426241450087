/** @jsx jsx */
import { Global, css, jsx } from '@emotion/react';

import * as React from 'react';

// This component should only contain css resets that aim to remove built-in browser styling.
const GlobalStyleResets = () => (
  <Global
    styles={css`
      html {
        scroll-behavior: smooth;
      }

      ul {
        padding-left: 0;
        list-style: none;
        margin: 0;
      }

      .unified-CML.unified-CML p:last-of-type {
        min-height: 0;
        margin-bottom: 0;
      }
    `}
  />
);

export default GlobalStyleResets;
