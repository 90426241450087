import { useEffect } from 'react';
import type React from 'react';

import { simplifyQueryValue } from 'js/lib/queryUtils';
import type { TrackingContextData } from 'js/lib/retracked';
import { useLocation } from 'js/lib/useRouter';

import { FRIENDBUY_PROMOTION_IDS, FRIENDBUY_STRING } from 'bundles/referral/constants';
import {
  handleScriptLoadFailure,
  handleScriptLoadSuccess,
  loadFriendbuyScript,
  trackCustomerEvent,
} from 'bundles/referral/utils/loadScriptUtils';

type Props = {
  promotionId?: number;
  shouldLoadFriendbuyScript?: boolean;
  trackingContextData: TrackingContextData;
};

const InstallFriendbuyScript: React.FC<Props> = ({ promotionId, trackingContextData, shouldLoadFriendbuyScript }) => {
  const location = useLocation();
  useEffect(() => {
    const shouldLoadScript =
      shouldLoadFriendbuyScript ||
      simplifyQueryValue(location?.query?.referral)?.toLowerCase() === FRIENDBUY_STRING ||
      (promotionId && FRIENDBUY_PROMOTION_IDS.includes(promotionId));

    if (shouldLoadScript) {
      trackCustomerEvent();
      loadFriendbuyScript(
        () => handleScriptLoadSuccess(trackingContextData),
        () => handleScriptLoadFailure(trackingContextData)
      );
    }
  }, [location.query.referral, promotionId, shouldLoadFriendbuyScript, trackingContextData]);

  return null;
};

export default InstallFriendbuyScript;
