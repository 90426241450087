import * as React from 'react';
import { Route } from 'react-router';

import { Route as CourseraRoute } from '@coursera/react-router';

import loadOnRoute from 'bundles/common/components/loadOnRoute';
import NotFound from 'bundles/phoenix/components/NotFound';
import ConsumerPageWrapper from 'bundles/unified-description-page-common/pages/consumer/ConsumerPageWrapper';

const ConsumerCoursePageRoot = loadOnRoute(
  () => import('bundles/unified-description-page-common/pages/consumer/course/ConsumerCoursePageRoot')
);
const ConsumerSpecializationPageRoot = loadOnRoute(
  () => import('bundles/unified-description-page-common/pages/consumer/specialization/ConsumerSpecializationPageRoot')
);
const ConsumerProfessionalCertificatePageRoot = loadOnRoute(
  () =>
    import(
      'bundles/unified-description-page-common/pages/consumer/professional-certificate/ConsumerProfessionalCertificatePageRoot'
    )
);
const ConsumerProfessionalCertificatePaidMediaPageRoot = loadOnRoute(
  () =>
    import(
      'bundles/unified-description-page-common/pages/consumer/professional-certificate/ConsumerProfessionalCertificatePaidMediaPageRoot'
    )
);
const ConsumerSpecializationPaidMediaPageRoot = loadOnRoute(
  () =>
    import(
      'bundles/unified-description-page-common/pages/consumer/specialization/ConsumerSpecializationPaidMediaPageRoot'
    )
);
const ConsumerCoursePaidMediaPageRoot = loadOnRoute(
  () => import('bundles/unified-description-page-common/pages/consumer/course/ConsumerCoursePaidMediaPageRoot')
);
const ConsumerProjectPageRoot = loadOnRoute(
  () => import('bundles/unified-description-page-common/pages/consumer/project/ConsumerProjectPageRoot')
);

export default (
  <Route>
    <CourseraRoute
      path="/"
      component={ConsumerPageWrapper}
      handle={{
        tracking: {
          segment: 'consumer',
        },
      }}
    >
      {[
        'learn/:productSlug',
        'learn/:productSlug/enroll',
        ':subfolderLocaleCode/learn/:productSlug',
        ':subfolderLocaleCode/learn/:productSlug/enroll',
      ].map((path) => (
        <CourseraRoute
          path={path}
          getComponent={ConsumerCoursePageRoot}
          handle={{
            tracking: {
              data: () => ({
                page: {
                  type: 'xdp_course',
                },
              }),
            },
          }}
        />
      ))}
      {['specializations/:productSlug', ':subfolderLocaleCode/specializations/:productSlug'].map((path) => (
        <CourseraRoute
          path={path}
          getComponent={ConsumerSpecializationPageRoot}
          handle={{
            tracking: {
              data: () => ({
                page: {
                  type: 'xdp_s12n',
                },
              }),
            },
          }}
        />
      ))}
      {['professional-certificates/:productSlug', ':subfolderLocaleCode/professional-certificates/:productSlug'].map(
        (path) => (
          <CourseraRoute
            path={path}
            getComponent={ConsumerProfessionalCertificatePageRoot}
            handle={{
              tracking: {
                data: () => ({
                  page: {
                    type: 'xdp_professional_cert',
                  },
                }),
              },
            }}
          />
        )
      )}

      {/* Paid Ads XDP v3: change from v2 below (https://coursera.atlassian.net/browse/MERCH-789) */}
      {[
        'professional-certificates/:productSlug/paidmedia',
        ':subfolderLocaleCode/professional-certificates/:productSlug/paidmedia',
      ].map((path) => (
        <CourseraRoute
          path={path}
          getComponent={ConsumerProfessionalCertificatePaidMediaPageRoot}
          handle={{
            tracking: {
              data: () => ({
                page: {
                  type: 'xdp_paid_media',
                },
              }),
            },
          }}
        />
      ))}
      {['specializations/:productSlug/paidmedia', ':subfolderLocaleCode/specializations/:productSlug/paidmedia'].map(
        (path) => (
          <CourseraRoute
            path={path}
            getComponent={ConsumerSpecializationPaidMediaPageRoot}
            handle={{
              tracking: {
                data: () => ({
                  page: {
                    type: 'xdp_paid_media',
                  },
                }),
              },
            }}
          />
        )
      )}
      {['learn/:productSlug/paidmedia', ':subfolderLocaleCode/learn/:productSlug/paidmedia'].map((path) => (
        <CourseraRoute
          path={path}
          getComponent={ConsumerCoursePaidMediaPageRoot}
          handle={{
            tracking: {
              data: () => ({
                page: {
                  type: 'xdp_paid_media',
                },
              }),
            },
          }}
        />
      ))}

      {/* Paid Ads XDP v2: product type must be provided in URL (https://coursera.atlassian.net/browse/HNX-2080) */}
      {[
        'enroll/professional-certificates/:productSlug/paidmedia',
        ':subfolderLocaleCode/enroll/professional-certificates/:productSlug/paidmedia',
      ].map((path) => (
        <CourseraRoute
          path={path}
          getComponent={ConsumerProfessionalCertificatePaidMediaPageRoot}
          handle={{
            tracking: {
              data: () => ({
                page: {
                  type: 'xdp_paid_media',
                },
              }),
            },
          }}
        />
      ))}
      {[
        'enroll/specializations/:productSlug/paidmedia',
        ':subfolderLocaleCode/enroll/specializations/:productSlug/paidmedia',
      ].map((path) => (
        <CourseraRoute
          path={path}
          getComponent={ConsumerSpecializationPaidMediaPageRoot}
          handle={{
            tracking: {
              data: () => ({
                page: {
                  type: 'xdp_paid_media',
                },
              }),
            },
          }}
        />
      ))}
      {['enroll/learn/:productSlug/paidmedia', ':subfolderLocaleCode/enroll/learn/:productSlug/paidmedia'].map(
        (path) => (
          <CourseraRoute
            path={path}
            getComponent={ConsumerCoursePaidMediaPageRoot}
            handle={{
              tracking: {
                data: () => ({
                  page: {
                    type: 'xdp_paid_media',
                  },
                }),
              },
            }}
          />
        )
      )}

      {/* Paid Ads XDP v1: URLs (prof certs only) do not specify product type: keep old route as default/fallback */}
      {['enroll/:productSlug/paidmedia', ':subfolderLocaleCode/enroll/:productSlug/paidmedia'].map((path) => (
        <CourseraRoute
          path={path}
          getComponent={ConsumerProfessionalCertificatePaidMediaPageRoot}
          handle={{
            tracking: {
              data: () => ({
                page: {
                  type: 'xdp_paid_media',
                },
              }),
            },
          }}
        />
      ))}

      {['projects/:productSlug', ':subfolderLocaleCode/projects/:productSlug'].map((path) => (
        <CourseraRoute
          path={path}
          getComponent={ConsumerProjectPageRoot}
          handle={{
            tracking: {
              data: () => ({
                page: {
                  type: 'xdp_project',
                },
              }),
            },
          }}
        />
      ))}
    </CourseraRoute>
    <Route path="*" component={NotFound} />
  </Route>
);
