// Adds event listener for fetching lazy loaded images.
// Useful for components that need to lazy load images,
// but still grant a performance lift by not contributing to initial page load.
import * as React from 'react';

import { DOCUMENT_COMPLETE_READY_STATE, fetchLazyImages } from 'bundles/page/components/shared/utils/lazyImgixUtils';

export default (Component: React.ComponentType) => {
  return class WithFetchLazyImages extends React.Component<{}, {}> {
    componentDidMount() {
      window.addEventListener('load', fetchLazyImages);
      // Sometimes the page is already done loading by the time we get here so the listener never fires.
      // Check ready state to see if document is already done loading.
      if (document.readyState === DOCUMENT_COMPLETE_READY_STATE) {
        fetchLazyImages();
      }
    }

    componentWillUnmount() {
      window.removeEventListener('load', fetchLazyImages);
    }

    render() {
      return <Component {...{ ...this.props }} />;
    }
  };
};
