import * as React from 'react';

import PropTypes from 'prop-types';
import { compose, getContext } from 'recompose';

import withFetchLazyImages from 'bundles/page/components/shared/utils/withFetchLazyImages';
import InstallFriendbuyScript from 'bundles/referral/components/InstallFriendbuyScript';
import UnifiedAppCheckProvider from 'bundles/unified-common/providers/UnifiedAppCheckProvider';
import GlobalStyleResets from 'bundles/unified-description-page-common/components/GlobalStyleResets';
import withMarketingConsent from 'bundles/user-consent/components/withMarketingConsent';

import 'css!bundles/unified-description-page-common/index';

type PropsFromCaller = {
  children?: JSX.Element;
};

type PropsFromLegacyContext = {
  _eventData?: $TSFixMe;
};

type PropsToComponent = PropsFromCaller & PropsFromLegacyContext;

const ConsumerPageWrapper = ({ children, _eventData }: PropsToComponent) => {
  return (
    <React.Fragment>
      <GlobalStyleResets />
      <UnifiedAppCheckProvider>
        <InstallFriendbuyScript trackingContextData={_eventData} />
        {children}
      </UnifiedAppCheckProvider>
    </React.Fragment>
  );
};

export default compose<PropsToComponent, PropsFromCaller>(
  withMarketingConsent,
  withFetchLazyImages,
  getContext({ _eventData: PropTypes.object })
)(ConsumerPageWrapper);
